import React, { useState, useEffect, useRef, useMemo } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { LocationOn as LocationOnIcon } from "@material-ui/icons";
import parse from "autosuggest-highlight/parse";
import { throttle } from "lodash";
import { Controller } from "react-hook-form";
import { isEqual } from "lodash/fp";

import { checkAddress } from "../utils/kits";

const API_KEY = "AIzaSyAJwlAHgX92J2zskWvNQHbM1FA5DTUvEpU";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function PlaceSearch({ control }) {
  const classes = useStyles();
  const [value, setSelectedValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        types: ["address"],
        componentRestrictions: { country: "us" },
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const loading = options.length < 1 && inputValue === "";

  return (
    <Controller
      name="recipient_address"
      control={control}
      rules={{
        validate: {
          notBlank: (value) =>
            value?.place_id !== "" || `please enter an address`,
          available: async (value) =>
            (value && checkAddress(value)) || `Invalid address`,
        },
      }}
      render={(props) => (
        <Autocomplete
          {...props}
          id="google-map-demo"
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.description
          }
          getOptionSelected={(option, value) => isEqual(option, value)}
          filterOptions={(x) => x}
          options={options}
          loading={loading}
          loadingText="Start typing your address"
          noOptionsText="No address found"
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={(_, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setSelectedValue(newValue);
            props.onChange({
              place_id: newValue?.place_id || "",
              description: newValue?.description || "",
              terms: newValue?.terms || [],
            });
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Add a location" variant="outlined" />
          )}
          renderOption={(option) => {
            const matches =
              option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [
                match.offset,
                match.offset + match.length,
              ])
            );

            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      )}
    />
  );
}
