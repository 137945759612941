import React from "react";
import { navigate } from "gatsby";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Error as ErrorIcon,
  CheckCircle as CheckIcon,
} from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { isEmpty } from "lodash";

import FormGroup from "../components/FormGroup";
import PlaceSearch from "../components/PlaceSearch";
import OrderLayout from "../components/OrderLayout";
import { updateLocalState, blankState } from "../utils/kits";

const useStyles = makeStyles((theme) => ({
  search: {
    margin: "0 auto",
  },
  heroText: {
    textAlign: "center",
  },
  description: {
    textAlign: "center",
    color: theme.palette.primary.dark,
    marginTop: "0.2em",
  },
  reset: {
    textAlign: "center",
    marginTop: "0.2em",
  },
}));

const IntroText = () => {
  const classes = useStyles();
  return (
    <Typography variant="subtitle1" className={classes.description}>
      Type the homeowner's address to see if someone already got a HomeClix kit
      for this address
    </Typography>
  );
};

const DuplicateAddress = ({ reset, action }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      spacing={0}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.reset}>
          We are sorry but someone has already ordered a HomeClix kit for this
          address
        </Typography>
      </Grid>
      <Grid item>
        <ErrorIcon color="error" aria-label="error" />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.reset}>
          Try another home
        </Typography>
      </Grid>
    </Grid>
  );
};

const CustomizeOrderStep = ({ formState }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      spacing={0}
    >
      <Grid item>
        <Button
          color="action"
          startIcon={<CheckIcon />}
          type="submit"
          disabled={!formState.isValid}
        >
          Customize
        </Button>
      </Grid>
    </Grid>
  );
};

const IndexPage = () => {
  const classes = useStyles();
  const { action, state } = useStateMachine(updateLocalState);
  const defaultValues = {
    recipient_address: { ...state?.data?.recipient_address },
  };
  console.log("state.data.recipient_address", state?.data?.recipient_address);

  const { control, errors, handleSubmit, formState, reset } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = (data) => {
    action(data);
    console.log("submitted data", data);
    navigate("/customize");
  };
  console.log("errors", errors);

  return (
    <OrderLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <FormGroup>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.heroText}>
                Make it a HomeClix Home
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {state?.data?.recipient_address?.place_id !== "" ? (
                <TextField
                  disabled
                  value={state?.data?.recipient_address?.description}
                  label="Selected location"
                  variant="outlined"
                  fullWidth
                />
              ) : (
                <PlaceSearch
                  name="address"
                  className={classes.search}
                  control={control}
                  fullWidth
                />
              )}
            </Grid>

            <Grid item xs={12}>
              {(!formState.isDirty ||
                (!formState.isValid && isEmpty(errors)) ||
                (!isEmpty(errors) &&
                  errors.recipient_address?.type === "notBlank")) && (
                <IntroText />
              )}
              {!isEmpty(errors) &&
                errors.recipient_address?.type !== "notBlank" && (
                  <DuplicateAddress reset={reset} action={action} />
                )}
              {formState.isValid && (
                <CustomizeOrderStep formState={formState} />
              )}
            </Grid>
          </FormGroup>
        </Grid>
      </form>
    </OrderLayout>
  );
};

export default IndexPage;
